<template>
  <div>
    <v-app-bar fixed color="navbar" app v-if="currentUser">
      <v-app-bar-nav-icon
        @click.stop="$emit('hide', !show)"
      ></v-app-bar-nav-icon>
      <!-- <v-toolbar-title>{{ title || 'Dashboard' }}</v-toolbar-title> -->

      <v-spacer></v-spacer>
      <div class="px-4">
        <v-spacer></v-spacer>
        <!-- <v-btn icon color="primary" @click="openDialog()">
          <v-icon>info</v-icon>
        </v-btn> -->

        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn text color v-on="on">
              <flag :iso="currentFlag" v-bind:squared="false" />
              <v-icon right color="primary">mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              v-for="(entry, index) in languagess"
              :key="`${index}010`"
              @click="changeLocale(entry)"
            >
              <v-list-item-title>
                <flag :iso="entry.flag" v-bind:squared="false" />
                &nbsp;{{ entry.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu
          bottom
          origin="center center"
          transition="scale-transition"
          offset-y
          v-if="currentUser"
        >
          <template v-slot:activator="{ on }">
            <v-btn icon small v-on="on" color="primary">
              <div>
                <v-badge
                  color="red"
                  :content="notifications"
                  bordered
                  overlap
                  left
                  v-if="notifications"
                >
                  <v-icon color="primary"> mdi-bell </v-icon>
                </v-badge>
                <v-icon color="primary" v-else> mdi-bell </v-icon>
              </div>
            </v-btn>
          </template>

          <v-card class="mx-auto" max-width="500">
            <v-list two-line>
              <v-list-item>
                <v-list-item-action>
                  <v-icon class="primary--text">mdi-message-text</v-icon>
                </v-list-item-action>
                <v-list-item-title>{{ $t("messages") }} </v-list-item-title>
                <v-list-item-action> ({{ notifications }}) </v-list-item-action>
              </v-list-item>
              <v-divider class="mx-3" style="margin-top: -4.5%"></v-divider>
              <v-list-item-group
                v-model="selected"
                active-class="primary--text"
              >
                <template v-for="(skeleton, x) in 3">
                  <v-skeleton-loader
                    :key="x"
                    type="list-item-avatar"
                    v-if="!loadMessages"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-for="(item, index) in chats">
                  <!-- <pre :key="index">
                {{item}}
              </pre> -->
                  <v-list-item
                    :key="item.name"
                    v-if="
                      (item.flag === 'Suport' &&
                        countReads(item.messages) > 0) ||
                        item.flag === 'Chat'
                    "
                    @click="
                      item.flag === 'Chat' ? initChat(item) : initSuport()
                    "
                  >
                    <!-- <template> -->
                    <template v-slot:default="{ active }">
                      <v-list-item-avatar>
                        <v-badge
                          bordered
                          bottom
                          color="primary"
                          dot
                          offset-x="15"
                          offset-y="11"
                        >
                          <v-avatar
                            size="35"
                            v-if="item.messages[0].to.id != currentUser.id"
                          >
                            <v-img
                              :src="
                                item.flag === 'Suport'
                                  ? item.messages[0].to.photo
                                    ? `${apiUrl}/images/partner/${item.messages[0].to.photo}`
                                    : '/profile.png'
                                  : item.messages[0].to.photo
                                  ? item.messages[0].to.type === 'entity'
                                    ? `${apiUrl}/images/user/${item.messages[0].to.photo}`
                                    : `${apiUrl}/images/partner/${item.messages[0].to.photo}`
                                  : '/profile.png'
                              "
                            ></v-img>
                          </v-avatar>
                          <v-avatar size="35" v-else>
                            <v-img
                              :src="
                                item.messages[0].author.photo
                                  ? `${apiUrl}/images/user/${item.messages[0].author.photo}`
                                  : '/profile.png'
                              "
                            ></v-img>
                          </v-avatar>
                        </v-badge>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="
                            item.flag === 'Suport'
                              ? item.messages[0].to.name === currentUser.name
                                ? 'Suporte Whirelab'
                                : 'Suporte Whirelab'
                              : item.messages[0].to.name === currentUser.name
                              ? getUserName(item.messages[0].author.name)
                              : getUserName(item.messages[0].to.name)
                          "
                        ></v-list-item-title>

                        <v-list-item-subtitle
                          class="text--primary"
                          v-text="item.messages[item.messages.length - 1].body"
                        ></v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-badge
                          color="red"
                          :content="countReads(item.messages)"
                          bordered
                          overlap
                          v-if="countReads(item.messages) > 0"
                        >
                          <v-icon v-if="!active" color="grey">
                            mdi-chat
                          </v-icon>

                          <v-icon v-else color="grey"> mdi-chat </v-icon>
                        </v-badge>
                        <v-btn block outlined color="primary" v-else>
                          start
                        </v-btn>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                  <v-divider
                    v-if="index < chats.length - 1"
                    :key="index"
                  ></v-divider>
                </template>
              </v-list-item-group>
              <v-list-item>
                <v-list-item-action>
                  <v-icon class="primary--text">mdi-account-group</v-icon>
                </v-list-item-action>
                <v-list-item-title>Candidaturas</v-list-item-title>
                <v-list-item-action>
                  ({{ countCandidances }})
                </v-list-item-action>
              </v-list-item>
              <!-- <pre> >>
                {{currentUser.entity.id}}
              </pre> -->
              <v-divider class="mx-3" style="margin-top: -4.5%"></v-divider>
            </v-list>
          </v-card>
        </v-menu>

        <v-menu
          bottom
          origin="center center"
          transition="scale-transition"
          offset-y
          v-if="currentUser"
          width="400px"
        >
          <template v-slot:activator="{ on }">
            <v-btn icon small v-on="on" color="primary">
              <v-avatar size="22" item>
                <img
                  v-if="currentUser.photo"
                  :src="`${apiUrl}/images/user/${currentUser.photo}`"
                />
                <v-icon v-else class="primary--text">mdi-account-circle</v-icon>
              </v-avatar>
            </v-btn>
          </template>

          <v-card class="mx-auto">
            <v-list two-line>
              <v-list-item>
                <v-list-item-avatar>
                  <img
                    :src="
                      currentUser.photo
                        ? `${apiUrl}/images/user/${currentUser.photo}`
                        : `/avatar.png`
                    "
                  />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ currentUser.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="caption">
                    {{ currentUser.email }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list>
              <v-list-item router to="/dashboard/entity/user/profile">
                <v-list-item-action>
                  <v-icon class="primary--text">mdi-account-circle</v-icon>
                </v-list-item-action>
                <v-list-item-title>{{
                  $t("form_candidate_profile")
                }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click.prevent="logout">
                <v-list-item-action>
                  <v-icon class="primary--text">mdi-logout-variant</v-icon>
                </v-list-item-action>
                <v-list-item-title>{{ $t("logout") }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>

        <v-menu
          v-if="accessLevel(this.currentUser, 'setting_list')"
          bottom
          origin="center center"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on }">
            <v-btn icon small>
              <v-icon v-on="on" color="primary">settings</v-icon>
            </v-btn>
          </template>

          <v-list>
            <template v-for="(setting, i) in settings">
              <v-list-item
                v-if="setting.show"
                route
                :key="i"
                :to="setting.route"
                :exact="setting.exact"
              >
                <v-list-item-action>
                  <v-icon color="primary">{{ setting.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-title>{{ setting.title }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </div>
      <alert-dialog
        v-if="!$apollo.queries.user.loading && currentUser"
        :dialog="false"
        :message="$t(alertMessage)"
        @close="alertDialog = false"
      />
    </v-app-bar>
    <v-alert
      v-if="moduleExpire && currentUser"
      text
      outlined
      dense
      type="error"
      class="mt-0 text-center"
    >
      {{ $t("subscription_expire", { user_name: `${currentUser.name}` }) }}
      <a @click="openPackageDialog()">{{ $t("subscription_expire2") }}</a>
      {{ $t("subscription_expire3") }}
      <a @click="openSupport()">{{ $t("subscription_expire4") }}</a>
    </v-alert>
    <v-alert
      v-else-if="moduleActive"
      text
      outlined
      dense
      type="error"
      class="mt-0 text-center"
      v-html="$t(alertMessage, { email: `<a>pagamentos@whirelab.com</a>` })"
      @click="openSupport()"
    >
    </v-alert>
    <v-alert
      v-if="module_about_to_expire && currentUser"
      text
      outlined
      dense
      type="error"
      class="mt-0 text-center"
    >
      {{
        $t("close_to_end_subscription", {
          username: `${currentUser.name}`,
          module_name: `${module_about_to_expire}`,
        })
      }}
      <a @click="openPackageDialog(module_about_to_expire)">{{ $t("here") }}</a>
      {{ $t("close_to_end_subscription2") }}<a>{{ $t("support_team") }}</a>
    </v-alert>
    <v-alert
      v-if="currentUser && limitCandidate"
      text
      outlined
      dense
      type="error"
      class="mt-0 text-center"
    >
      {{ $t("limit_for_candidates", { module_name: limitCandidate }) }}
      <a @click="openPackageDialog()">{{ $t("here") }}</a>
      {{ $t("limit_for_candidates2") }} <a> {{ $t("support_team") }} </a>
    </v-alert>
    <plan-dialog />
    <!-- <upgrade-package-dialog /> -->
    <package-dialog />
  </div>
</template>

<script>
import { API_URL } from "@/api";
import { GC_AUTH_TOKEN, GC_USER_ID } from "@/constants/settings";
import {
  USER_QUERY,
  // GET_BLOCKED_CANDIDATES_QUERY,
  GET_TRAINING_AREAS,
} from "../graphql/Query.gql";
import { mapActions, mapGetters } from "vuex";
import { CHATS_ROOM_QUERY } from "./../modules/chat/graphql/Query";
import {
  MESSAGE_SENT_SUBSCRIPTION,
  UPDATED_COUNT_CHAT,
} from "./../modules/chat/graphql/subscription";
import { SEND_STATUS } from "../graphql/Mutation.gql";
import Vue2Filters from "vue2-filters";
import { GET_NEW_VACANCY } from "../modules/vacancy/graphql/Query.resolver";
import accessLevelMixins from "@/mixins/access-level";
import compareDatesMixins from "@/mixins/compare-dates";
import moduleHasPermission from "@/mixins/module-has-permission";
import AlertDialog from "./AlertDialog.vue";
import PlanDialog from "./PlanDialog.vue";
// import UpgradePackageDialog from './UpgradePackageDialog.vue';
import PackageDialog from "./package/Index.vue";
import { CANDIDATE_NOTIFY_CANDIDANCE_SUBSCRIPTION } from "../modules/vacancy/graphql/Subscraption.resolver";
export default {
  name: "AppToolbar",
  components: {
    AlertDialog,
    PackageDialog,
    PlanDialog,
    // UpgradePackageDialog
  },
  props: {
    show: Boolean,
  },
  mixins: [
    accessLevelMixins,
    compareDatesMixins,
    Vue2Filters.mixin,
    moduleHasPermission,
  ],
  model: {
    prop: "show",
    event: "hide",
  },

  data: () => ({
    apiUrl: API_URL,
    alertDialog: true,
    alertMessage: "confirm_subcription",
    currentFlag: "pt",
    showLogoutDialog: false,
    user: null,
    notifications: 0,
    chats: [],
    selected: [2],
    loadMessages: false,
    flag: true,
    vacancies: [],
  }),

  apollo: {
    user: {
      query: USER_QUERY,
      fetchPolicy: "no-cache",
    },
    getTrainingAreas: {
      query: GET_TRAINING_AREAS,
      result({ data }) {
        this.setTrainingAreas(data.getTrainingAreas);
      },
    },
    // blockedCandidates: {
    //   query: GET_BLOCKED_CANDIDATES_QUERY,
    //   fetchPolicy: "no-cache",
    //   result({ data }) {
    //     console.log(data);
    //     this.setBlockedCandidates(data.blockedCandidates);
    //   },
    // },
    getNewVacancies: {
      query: GET_NEW_VACANCY,
      fetchPolicy: "no-cache",
      result({ data }) {
        console.log(data);
        this.vacancies = data.getNewVacancies;
      },
    },
    recivedChats: {
      query: CHATS_ROOM_QUERY,
      fetchPolicy: "no-cache",
      result({ data }) {
        if (data.recivedChats) {
          this.loadMessages = true;
          console.log("recivedChats toolBar", data.recivedChats);
          data.recivedChats.forEach((chat) => {
            if (
              chat.messages[chat.messages.length - 1].to.id !=
              chat.messages[chat.messages.length - 1].author.id
            ) {
              this.chats.push(chat);
            }
          });
          this.chats.forEach((element) => {
            this.notifications += this.countReads(element.messages);
          });
        }
      },
    },
    $subscribe: {
      message: {
        query: MESSAGE_SENT_SUBSCRIPTION,
        result({ data }) {
          if (
            data.addChat.messages.to.id === data.addChat.messages.author.id &&
            data.addChat.flag === "Suport"
          ) {
            return;
          }
          if (data.addChat.messages.to.id === this.currentUser.id) {
            console.log("=>>>>>", data.addChat);
            this.playSound();
            this.getMessage();
          }
        },
      },
      updateCountChat: {
        query: UPDATED_COUNT_CHAT,
        result({ data }) {
          var index = this.chats.findIndex(
            (u) => u.room == data.updateCountChat.room
          );
          if (index > -1) {
            this.chats[index].messages = data.updateCountChat.messages;
            this.notifications = 0;
            this.chats.forEach((element) => {
              this.notifications += this.countReads(element.messages);
            });
          }
        },
      },
      candidateNotify: {
        query: CANDIDATE_NOTIFY_CANDIDANCE_SUBSCRIPTION,
        result({ data }) {
          console.log("notifica candidatura", data.candidateNotify);
        },
      },
    },
  },

  watch: {
    user: function(val) {
      this.setUserState(val);
    },
  },
  unmounted() {},
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
      modulesExpired: "auth/getModulesExpired",
      modules: "auth/getModules",
    }),
    countCandidances() {
      let sum = 0;
      if (this.vacancies.length > 0) {
        this.vacancies.forEach((element) => {
          if (
            element.entity &&
            this.currentUser.entity.id === element.entity.id
          ) {
            sum += element.candidacies.length;
          }
        });
      }
      return sum;
    },
    languagess: function() {
      return [
        { flag: "gb", language: "en", title: "English" },
        // { flag: "fr", language: "fr", title: "Français" },
        { flag: "pt", language: "pt", title: "Português" },
      ];
    },
    limitCandidate() {
      const totalCandidates = this.currentUser.entity.totalCandidates;
      const modules = this.modules.map((mapModule) => mapModule.module);

      const modulesFilter = modules.filter((filterModule, index) => {
        return modules.indexOf(filterModule) === index;
      });

      const findedIndex = modulesFilter.findIndex((findedModule) => {
        const transactions = this.modules.filter(
          (transaction) => transaction.module.id === findedModule.id
        );

        const totalProfiles = transactions.reduce((accumulate, m) => {
          return accumulate + m.totalProfiles;
        }, 0);

        return totalProfiles - totalCandidates === findedModule.minimumProfiles;
      });

      console.log("modulesFilter[findedIndex]: ", modulesFilter[findedIndex]);

      return findedIndex >= 0 ? modulesFilter[findedIndex].name : null;
    },
    moduleActive() {
      const length = this.modules.length;
      if (length && length > 0) {
        return false;
      }
      return true && this.alertDialog;
    },
    moduleExpire() {
      const lengthExpired = this.modulesExpired.length;
      const length = this.modules.length;
      if (length > lengthExpired) {
        return false;
      }
      return true && this.alertDialog && lengthExpired > 0;
    },
    modulesNames() {
      let names = "";
      for (let index = 0; index < this.modules.length; index++) {
        if (this.modules.length > 1 && index === this.modules.length - 1) {
          const locale = localStorage.getItem("lang");
          if (locale === "en") {
            names += `and ${this.modules[index].module.name}`;
          } else {
            names += `e ${this.modules[index].module.name}`;
          }
        } else {
          names += `${this.modules[index].module.name}, `;
        }
      }

      return names;
    },
    module_about_to_expire() {
      if (this.modules.length > 0) {
        const modules = this.modules.filter((module) => {
          const distance = this.distanceBetweenDatesInDays({
            date: module.date,
            days: module.plan.duration,
          });
          return Number(distance[0]) <= module.plan.minimumDuration;
        });

        let names = "";
        const arrayOfNames = modules.map((module) => module.module.name);
        const arrayOfNamesFilter = arrayOfNames.filter((name, index) => {
          return arrayOfNames.indexOf(name) === index;
        });

        if (arrayOfNamesFilter.length > 1) {
          for (let index = 0; index < arrayOfNamesFilter.length; index++) {
            if (index === arrayOfNamesFilter.length - 1) {
              const locale = localStorage.getItem("lang");
              if (locale === "en") {
                names += `and ${arrayOfNamesFilter[index]}`;
              } else {
                names += `e ${arrayOfNamesFilter[index]}`;
              }
            } else {
              names += `${arrayOfNamesFilter[index]}, `;
            }
          }
        } else {
          names = arrayOfNamesFilter[0];
        }

        return names;
      } else {
        return false;
      }
    },
    settings: function() {
      return [
        {
          icon: "mdi-domain",
          title: this.$t("institutionprofile"),
          route: "/dashboard/entity/profile",
          exact: true,
          show: this.accessLevel(
            this.currentUser,
            "setting_compony_profile_edit"
          ),
        },
        {
          icon: "mdi-key-variant",
          title: this.$t("AccessLevel"),
          route: "/dashboard/entity/roles",
          exact: true,
          show: this.accessLevel(this.currentUser, "setting_access_level"),
        },
        {
          icon: "mdi-school-outline",
          title: this.$t("academic_education"),
          route: "/dashboard/entity/training-area",
          exact: true,
          // show: this.accessLevel(this.currentUser, "setting_access_level"),
          show: true,
        },
        {
          icon: "mdi-account-group",
          title: this.$t("user"),
          route: "/dashboard/entity/users",
          exact: true,
          show: this.accessLevel(this.currentUser, "setting_user"),
        },
      ];
    },
    userId() {
      return this.$root.$data.userId;
    },
  },

  created() {
    window.addEventListener("beforeunload", this.leaving);
    if (localStorage.getItem("lang") != null) {
      this.$i18n.locale = localStorage.getItem("lang");
      this.currentFlag = localStorage.getItem("flag");
    } else {
      this.$i18n.locale = "pt";
      this.currentFlag = "pt";
    }
  },
  methods: {
    ...mapActions({
      setUserState: "auth/setUser",
      setBlockedCandidates: "candidate/setBlockedCandidates",
      setTrainingAreas: "library/setTrainingAreas",
    }),
    getUserName(name) {
      name = name.split(" ");
      return name[0];
    },
    async playSound() {
      var audio = new Audio("/whirelabRing.wav");
      audio.muted = false;
      audio.autoplay = true;
      audio.load();
      var playPromise = audio.play();
      console.log(playPromise);
      if (playPromise !== undefined) {
        await playPromise
          .then((_) => {
            console.log("play", _);
            audio.play();
          })
          .catch((error) => {
            console.log("err", error);
          });
      }
    },
    changeLocale(locale) {
      this.$i18n.locale = locale.language;
      this.currentFlag = locale.flag;

      localStorage.setItem("lang", locale.language);
      localStorage.setItem("flag", locale.flag);

      console.log(locale, localStorage.getItem("lang"));
      localStorage.setItem("localeCV", locale.language);
      this.backOnline();
    },
    async loadUser() {
      await this.$apollo.mutate({});
    },
    logout() {
      this.logoutPropreties();
      this.leaving();
    },

    logoutPropreties() {
      localStorage.removeItem(GC_USER_ID);
      localStorage.removeItem(GC_AUTH_TOKEN);
      this.$root.$data.userId = localStorage.getItem(GC_USER_ID);
      this.$router.push("/login");
      // this.leaving();
    },
    countReads(messages) {
      return messages.filter(
        (m) => m.read === "1" && m.to.id === this.currentUser.id
      ).length;
    },
    async initChat(valor) {
      // console.log("valor", valor);
      if (valor.messages[0].to.id === this.currentUser.id) {
        valor = [{ ...valor, to: valor.messages[0].author.id }];
      } else if (valor.messages[0].author.id === this.currentUser.id) {
        valor = [{ ...valor, to: valor.messages[0].to.id }];
      }
      this.$emit("initChat", valor[0]);
    },
    initSuport() {
      this.$emit("initChatSuport");
    },
    async leaving() {
      await this.$apollo.mutate({
        mutation: SEND_STATUS,
        variables: {
          user_id: this.currentUser.id,
          description: "Offline",
          locale: this.$i18n.locale,
        },
      });
    },
    async backOnline() {
      if (this.currentUser) {
        await this.$apollo.mutate({
          mutation: SEND_STATUS,
          variables: {
            user_id: this.currentUser.id,
            description: "Online",
            locale: this.$i18n.locale,
          },
        });
      }
    },
    async getMessage() {
      await this.$apollo
        .query({
          query: CHATS_ROOM_QUERY,
          fetchPolicy: "no-cache",
        })
        .then((res) => {
          if (res.data.recivedChats) {
            console.log("list", res.data.recivedChats);
            this.chats = res.data.recivedChats;
            this.notifications = 0;
            // this.chats =[]
            // res.data.recivedChats.forEach((chat) => {
            //   if ( chat.messages.to.id != chat.messages.author.id) {
            //     this.chats.push(chat);
            //   }
            // });
            this.chats.forEach((element) => {
              this.notifications += this.countReads(element.messages);
            });
            console.log("ordered", res.data.recivedChats);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    openPackageDialog(module_about_to_expire) {
      if (module_about_to_expire) {
        // eslint-disable-next-line no-undef
        Fire.$emit("packageDialogCloseToExpire", module_about_to_expire);
      } else {
        // eslint-disable-next-line no-undef
        Fire.$emit("packageDialog", 0);
      }
    },
    openSupport() {
      alert("Chat Support!");
    },
  },
  mounted() {
    setTimeout(() => {
      this.backOnline();
      this.$emit("hide", false);
    }, 1000);
  },
};
</script>
